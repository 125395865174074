import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import ArrowRight from '../../../../images/pricing/arrow-right.svg'

export const QuestionWrapper = styled.div`
  margin-bottom: 112px;
  h2 {
    margin-bottom: 56px;
    color: #1f2329;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
  }
  ${Media.phone`
    margin: 0 0 0.76rem;
    h2 {
      font-size: 0.48rem;
      margin-bottom: 0.56rem;
    }
  `}
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  .question {
    overflow: hidden;
    width: 100%;
    border-radius: 6px;
    background: #f6f6f6;
    h4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      cursor: pointer;
      color: #1f2329;
      font-size: 18px;
      font-weight: 700;
      transition: padding 0.1s;
      &::after {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        background: url(${ArrowRight}) center / 100% 100% no-repeat;
        transition: transform 0.1s;
      }
      &:hover {
        padding-left: 34px;
      }
    }
    p {
      display: none;
      padding: 0 30px 20px;
      color: ${color.textDark};
      font-size: 18px;
    }
  }
  .unfold {
    background: #f5f7ff;
    h4::after {
      transform: rotate(90deg);
    }
    p {
      display: block;
    }
  }
  ${Media.phone`
    row-gap: 0.24rem;
    padding: 0 0.32rem;
    .question {
      width: 100%;
      border-radius: 0.12rem;
      h4 {
        align-items: flex-start;
        padding: 0.32rem 0.36rem;
        font-size: 0.3rem;
        &:hover {
          padding-left: 0.36rem;
        }
        &::after {
          width: 0.32rem;
          height: 0.32rem;
        }
      }
      p {
        padding: 0 0.36rem 0.36rem;
        font-size: 0.28rem;
      }
    }
`}
`
