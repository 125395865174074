import React, { useState, useEffect } from 'react'
import {
  defaultPlatform,
  getDownloadLink,
  downloadCount,
} from '@raylink-overseas/common/utils'
import { useMedia } from '@raylink-overseas/common/hooks'
import { Warpper } from '@raylink-overseas/common/components'
import { Platform } from '@raylink-overseas/common/constants/platform'
import { BannerWrapper, Step, StepContainer, SubTitle, Title } from './atoms'
import OpenWin from '../../../../images/install/open-win.png'
import InstallWin from '../../../../images/install/install-win.png'
import UseWin from '../../../../images/install/use-win.png'
import OpenMac from '../../../../images/install/open-mac.png'
import InstallMac from '../../../../images/install/install-mac.png'
import UseMac from '../../../../images/install/use-mac.png'
import Line from '../../../../images/install/line.svg'
import LineMobile from '../../../../images/install/line-mobile.svg'

const banner = [
  {
    step: 'Step 1',
    title: 'Open',
    desc: 'Open the Avica install file from the Downloads folder.',
    winImg: OpenWin,
    macImg: OpenMac,
  },
  {
    step: 'Step 2',
    title: 'Install',
    desc: 'Follow the intructions to install on your computer.',
    winImg: InstallWin,
    macImg: InstallMac,
  },
  {
    step: 'Step 3',
    title: 'Start to Use',
    desc: 'After the installation, Avica client will start automatically.',
    winImg: UseWin,
    macImg: UseMac,
  },
]

export default function Banner() {
  const isMedia = useMedia()
  const [platform, setPlatform] = useState(Platform.windows)
  const [downloadLink, setDownloadLink] = useState('')
  const [version, setVersion] = useState('')
  const requestDownloadLink = async (p: number) => {
    const { downloadLink, version } = await getDownloadLink(p)
    const dLink = p === Platform.android ? '/downloads/' : downloadLink
    setDownloadLink(dLink)
    setVersion(version)
  }

  useEffect(() => {
    const platform = defaultPlatform().platform!
    setPlatform(platform)
    requestDownloadLink(platform)
  }, [])

  return (
    <BannerWrapper>
      <Warpper>
        <Title>
          <div className="text">Downloading & Installing Avica Is So Easy</div>
          <img src={isMedia ? LineMobile : Line} alt="" />
        </Title>
        {/* 针对Mac端页面，点击Click here to redownload锚文本，跳转至本页面Redownload Avica for Mac版块 */}
        <SubTitle>
          <span>
            {platform === Platform.mac
              ? 'Facing download or launch issues? '
              : 'Download not working? '}
          </span>
          <a
            href={platform === Platform.mac ? '#redownload' : downloadLink}
            rel="nofollow"
            onClick={() => {
              if (
                typeof platform !== 'number' ||
                [Platform.ios, Platform.android, Platform.mac].includes(
                  platform
                )
              )
                return
              downloadCount(platform!, version)
            }}
          >
            Click here to redownload
          </a>
        </SubTitle>

        <StepContainer>
          {banner.map(b => (
            <Step key={b.step} text={b.step}>
              <div className="step-wrapper">
                <h4>{b.title}</h4>
                <p>{b.desc}</p>
                <img
                  src={platform === Platform.windows ? b.winImg : b.macImg}
                  alt=""
                />
              </div>
            </Step>
          ))}
        </StepContainer>
      </Warpper>
    </BannerWrapper>
  )
}
