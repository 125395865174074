import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import googlePlay from '../../../images/download/btn-google-play.png'
import AndroidGooglePlay from '@images/download/android-google-play.png'
import AndroidGalaxyStore from '@images/download/android-galaxy-store.png'
import AndroidAppGallery from '@images/download/android-app-gallery.png'
import AndroidQrcode from '@images/download/android-qrcode.svg'
import AndroidDownloadAPKQrcode from '@images/download/android-qrcode-download-apk.svg'

export const DownloadForBtn = styled.a<{
  isGray?: boolean
  isWhite?: boolean
  height?: number
}>`
  height: ${({ height }) => (height ? `${height}px` : '44px')};
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  color: ${({ isWhite }) => (isWhite ? color.primary : '#fff')};
  background: ${({ isWhite, isGray }) =>
    isWhite
      ? '#fff'
      : isGray
      ? 'linear-gradient(0deg, rgba(238, 238, 238, 0.90) 0%, rgba(238, 238, 238, 0.90) 100%), linear-gradient(90deg, #197ef3 0%, #445fff 100%)'
      : 'linear-gradient(290deg, #445FFF 53.77%, #55BCEE 97.08%)'};
  &:hover {
    color: ${({ isWhite }) => (isWhite ? color.primary : '#fff')};
    background: ${({ isWhite, isGray }) =>
      isWhite
        ? '#f5f7ff'
        : isGray
        ? 'linear-gradient(0deg, rgba(238, 238, 238, 0.90) 0%, rgba(238, 238, 238, 0.90) 100%), linear-gradient(90deg, #197ef3 0%, #445fff 100%)'
        : 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(90deg, #197ef3 0%, #445fff 100%)'};
  }
  &:active {
    background: ${({ isWhite, isGray }) =>
      isWhite
        ? '#eef1fa'
        : isGray
        ? 'linear-gradient(0deg, rgba(238, 238, 238, 0.90) 0%, rgba(238, 238, 238, 0.90) 100%), linear-gradient(90deg, #197ef3 0%, #445fff 100%)'
        : 'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), linear-gradient(90deg, #197ef3 0%, #445fff 100%)'};
  }

  ${Media.phone`
    height: 0.88rem;
    padding: 0 0.46rem;
    flex-shrink: 0;
    font-size: 0.32rem;
    border-radius: 0.08rem;
  `}
`

export const DownloadInGoogle = styled.div`
  width: 194px;
  height: 58px;
  background: url(${googlePlay}) no-repeat;
  background-size: cover;
  ${Media.phone`
    width: 3.88rem;
    height: 1.16rem;
  `}
`

export const DownloadInStore = styled.div`
  ${Media.phone`
    svg {
      width: 3.48rem;
      height: 1.16rem;
    }
  `}
`

export const AndroidBtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 393px;
  ${Media.phone`
    gap: 0.14rem;
    width: 100%;
  `}
`

export const AndroidDownloadBtn = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 182px;
  height: 54px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #adadad;
  .qrcode {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: -999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 8px;
    width: 148px;
    height: 0;
    opacity: 0;
    border-radius: 8px;
    border: 1px solid transparent;
    background: #fff;
    box-shadow: 0px 2px 16px 2px rgba(204, 204, 204, 0.24);
    transition: all 0.2s ease 0.1s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 26px;
    height: 26px;
    opacity: 1;
    background: url(${AndroidQrcode}) center / 100% 100% no-repeat;
    transition: all 0.2s ease 0.1s;
  }
  &.btn-google-play {
    background: url(${AndroidGooglePlay}) center / 164px 44px no-repeat, #000;
  }
  &.btn-galaxy-store {
    width: 199px;
    height: 54px;
    background: url(${AndroidGalaxyStore}) center / 185px 41px no-repeat, #000;
  }
  &.btn-app-gallery {
    background: url(${AndroidAppGallery}) center / 160px 38px no-repeat, #000;
  }
  &.btn-apk-download {
    width: 199px;
    height: 54px;
    border: 1px solid ${color.primary};
    color: ${color.primary};
    font-size: 16px;
    font-weight: 500;
    &::after {
      background: url(${AndroidDownloadAPKQrcode}) center / 100% 100% no-repeat;
    }
  }
  &:hover {
    .qrcode {
      opacity: 1;
      bottom: -8px;
      z-index: 999;
      height: 148px;
      border: 1px solid #e6e6e6;
    }
    &::after {
      opacity: 0;
    }
  }
  ${Media.phone`
    width: 3.06rem;
    height: 0.9rem;
    border-radius: 0.12rem;
    .qrcode, &::after {
      display: none;
    }
    &.btn-google-play {
      background: url(${AndroidGooglePlay}) center / 2.74rem 0.73rem no-repeat, #000;
    }
    &.btn-galaxy-store {
      width: 3.34rem;
      height: 0.9rem;
      background: url(${AndroidGalaxyStore}) center / 3.10rem 0.68rem no-repeat, #000;
    }
    &.btn-app-gallery {
      background: url(${AndroidAppGallery}) center / 2.70rem 0.62rem no-repeat, #000;
    }
    &.btn-apk-download {
      width: 3.34rem;
      height: 0.9rem;
      font-size: 0.32rem;
      &::after {
        display: none;
      }
    }
  `}
`
