import React, { useState, useEffect } from 'react'
import MacReImg from '../../../../images/download/macRe.png'
import { MacReWrap, MacReContent } from './atoms'
import MacBtns from '../../../components/MacBtns'
import {
  defaultPlatform,
  type DefaultPlatform,
} from '@raylink-overseas/common/utils'
import { Platform } from '@raylink-overseas/common/constants/platform'

export default function MacReDownload() {
  const [platform, setPlatform] = useState<DefaultPlatform>({})

  useEffect(() => {
    if (typeof window === 'undefined') return
    const p = defaultPlatform()
    setPlatform(p)
    // 解决锚点跳转-顶部导航栏遮挡问题
    const htmlEle = document.querySelector('html')
    if (htmlEle) htmlEle.style.scrollPaddingTop = '68px'
  }, [])

  return (
    platform.platform === Platform.mac && (
      <MacReWrap id="redownload">
        <MacReContent>
          <div className="container">
            <div className="re-title">Redownload Avica for Mac</div>
            <div className="re-text">
              To enhance your remote experience, please click the button below
              to download proper Avica version according to your Mac&lsquo;s
              chip.
            </div>
            <MacBtns />
          </div>
        </MacReContent>
        <img src={MacReImg} alt="" />
      </MacReWrap>
    )
  )
}
