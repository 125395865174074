import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import Banner from '../../../../images/install/banner.png'
import BannerMobile from '../../../../images/install/banner-avica-mobile.svg'
import WhiteBg from '../../../../images/install/white-bg.png'
import LeftTop from '../../../../images/install/left-top.svg'

export const BannerWrapper = styled.div`
  min-width: 1200px;
  padding: 164px 0 112px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 70.12%, #fff 100%),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.24) 47.9%
    ),
    linear-gradient(90deg, #dbf0ff 0%, #e8f3ff 51.4%, #cee2ff 99.69%);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-width: 1200px;
    height: 812px;
    background: url(${Banner}) center / cover no-repeat;
  }
  ${Media.phone`
    min-width: 100%;
    position: relative;
    padding: 1.78rem 0 0.76rem;
    background: 
      linear-gradient(180deg, rgba(255, 255, 255, 0.00) 93.11%, #FFF 100%), 
      linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.24) 23.32%), 
      linear-gradient(90deg, #DBF0FF 0%, #E8F3FF 51.4%, #CEE2FF 99.69%);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: unset;
      min-width: 100%;
      height: 6.44rem;
      transform: none;
      background: url(${BannerMobile}) center / 100% 100% no-repeat;
    }
  `}
`

export const Title = styled.h1`
  position: relative;
  margin: 0 auto;
  width: fit-content;
  color: #1f2329;
  text-align: center;
  font-size: 48px;
  font-weight: 800;
  .text {
    position: relative;
    z-index: 99;
    white-space: nowrap;
  }
  img {
    position: absolute;
    right: 0;
    bottom: -14px;
    width: 375px;
    height: 30px;
  }
  ${Media.phone`
    padding: 0 0.32rem;
    font-size: 0.72rem;
    font-weight: 800;
    line-height: 1.2;
    .text {
      white-space: normal;
    }
    img {
      left: 50%;
      bottom: -0.13rem;
      transform: translateX(-50%);
      width: 2.96rem;
      height: 0.3rem;
    }
  `}
`

export const SubTitle = styled.p`
  margin: 24px 0 64px;
  color: ${color.textDark};
  text-align: center;
  font-size: 18px;
  a {
    color: ${color.primary};
  }
  ${Media.phone`
    margin: 0.64rem 0;
    & > * {
      display: block;
      font-size: 0.32rem;
    }
  `}
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${Media.phone`
    padding: 0 0.52rem;
    row-gap: 0.36rem;
  `}
`

export const Step = styled.div<{ text?: string }>`
  position: relative;
  overflow: hidden;
  padding: 68px 32px 36px;
  width: 370px;
  border-radius: 16px;
  background: #fff;
  &::before {
    content: '${({ text }) => text ?? ''}';
    position: absolute;
    top: -2px;
    left: -2px;
    background: url(${LeftTop}) center / 100% 100% no-repeat;
    padding: 13px 0 0 40px;
    width: 208px;
    height: 58px;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
  }
  &::after {
    content: '';
    position: absolute;
    inset: -1px;
    background: url(${WhiteBg}) center / 100% 100% no-repeat;
  }
  .step-wrapper {
    position: relative;
    z-index: 99;
    h4 {
      padding: 0 8px;
      color: #1f2329;
      font-size: 24px;
      font-weight: 700;
    }
    p {
      padding: 0 8px;
      margin: 8px 0 24px;
      color: ${color.textDark};
      font-size: 16px;
    }
    img {
      width: 100%;
    }
  }
  ${Media.phone`
    width: 100%;
    padding: 1.12rem 0.56rem 0.65rem;
    border-radius: 0.32rem;
    &::before {
      padding: 0.18rem 0 0 0.68rem;
      width: 3.64rem;
      height: 1rem;
      font-size: 0.32rem;
    }
    .step-wrapper {
      h4 {
        padding: 0 0.12rem;
        font-size: 0.4rem;
      }
      p {
        margin: 0.16rem 0 0.32rem;
        padding: 0 0.12rem;
        font-size: 0.28rem;
      }
    }
  `}
`
