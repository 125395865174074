import React, { useState } from 'react'
import { QuestionContainer, QuestionWrapper } from './atoms'
import { Warpper } from '@raylink-overseas/common/components'

const questions = [
  {
    question: 'Where can I see the downloaded Avica file?',
    answer:
      "The recently downloaded Avica file will appear to the right of the address bar. If the Download tray isn't present, click More > Downloads.",
  },
  {
    question: 'Why do I get a security alert when installing Avica?',
    answer:
      "Please rest assured that Avica is safe and secure. If prompted, click 'Install anyway' and 'Yes' on the system dialogues.",
  },
  {
    question: 'How long does it take to install Avica?',
    answer:
      'The time it takes to install Avica depends mainly on the specs and network condition of your computer. As a rough average, it can take somewhere around 1–3 minutes. If the installation is taking too long, please contact us for help.',
  },
]

export default function Question() {
  const [unfolds, setUnfolds] = useState(
    Array.from({ length: questions.length }, () => false)
  )
  return (
    <QuestionWrapper>
      <Warpper>
        <h2>Frequently Asked Questions</h2>
        <QuestionContainer>
          {questions.map(({ question, answer }, idx) => (
            <div
              className={`question ${unfolds[idx] ? 'unfold' : ''}`}
              key={idx}
            >
              <h4
                onClick={() => {
                  unfolds[idx] = !unfolds[idx]
                  setUnfolds([...unfolds])
                }}
              >
                {question}
              </h4>
              <p>{answer}</p>
            </div>
          ))}
        </QuestionContainer>
      </Warpper>
    </QuestionWrapper>
  )
}
