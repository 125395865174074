import React from 'react'
import { HelpCard, HelpWrapper, Button } from './atoms'
import { Warpper } from '@raylink-overseas/common/components'
import Contact from '../../../../images/install/contact.png'
import HelpCenter from '../../../../images/install/help-center.png'
import useHref from '@raylink-overseas/common/hooks/use-href'

const help = [
  {
    title: 'Need help?',
    desc: 'Contact us and get assistance',
    img: Contact,
    btnText: 'Contact Us',
    link: '/contact',
  },
  {
    title: 'Get Started',
    desc: 'Tips and tricks to start Avica remote journey',
    img: HelpCenter,
    btnText: 'Help Center',
    link: '/help-center',
  },
]

export default function Help() {
  const paramsHref = useHref()
  return (
    <Warpper>
      <HelpWrapper>
        {help.map(h => (
          <HelpCard key={h.title}>
            <h4>{h.title}</h4>
            <p>{h.desc}</p>
            <Button href={paramsHref(h.link)}>{h.btnText}</Button>
            <img src={h.img} alt="" />
          </HelpCard>
        ))}
      </HelpWrapper>
    </Warpper>
  )
}
