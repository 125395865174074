import { Warpper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const MacReWrap = styled(Warpper)`
  position: relative;
  margin-bottom: 112px;
  padding-top: 62px;
  img {
    position: absolute;
    bottom: 0;
    left: 558px;
    width: 642px;
    height: 372px;
  }
  ${Media.phone`
    margin-bottom: 0.76rem;
    padding-top: 0;
    width: 6.46rem;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 6.46rem;
      height: auto;
    }
  `}
`

export const MacReContent = styled.div`
  width: fit-content;
  padding: 54px 300px 58px 64px;
  border-radius: 32px;
  background: linear-gradient(92.15deg, #f5f7ff 46.56%, #e7f1ff 100%);
  .container {
    width: 470px;
    .re-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 1.5;
    }
    .re-text {
      font-size: 18px;
      line-height: 1.25;
      margin: 12px 0 24px;
      color: #595959;
    }
  }
  ${Media.phone`
    padding: 0.48rem 0.4rem 4.2rem;
    border-radius: 0.32rem;
    .container {
      text-align: center;
      width: 5.66rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .re-title {
        font-size: 0.44rem;
        width: 3.8rem;
      }
      .re-text {
        font-size: 0.32rem;
        margin: 0.16rem 0 0.32rem;
      }
    }
  `}
`
