import { Media, color } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const Button = styled.a<{ disabled?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 134px;
  border-radius: 6px;
  background: linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
  box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0.05) 100%
      ),
      linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
    color: #fff;
  }
  &:active {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
    color: #fff;
  }
  &[disabled] {
    background: linear-gradient(
        0deg,
        rgba(238, 238, 238, 0.9) 0%,
        rgba(238, 238, 238, 0.9) 100%
      ),
      linear-gradient(290deg, #445fff 53.77%, #55bcee 97.08%);
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
  ${Media.phone`
    padding: 0.2rem 0;
    width: 2.22rem;
    border-radius: 0.08rem;
    font-size: 0.28rem;
  `}
`

export const HelpWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 112px;
  ${Media.phone`
    flex-wrap: wrap;
    row-gap: 0.32rem;
    padding: 0.32rem;
    margin-bottom: 0.76rem;
  `}
`

export const HelpCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 576px;
  padding: 48px 82px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #ebefff;
  background: linear-gradient(281deg, #f6f7ff 0%, #fff 28.74%);
  h4 {
    color: #1f2329;
    font-size: 28px;
    font-weight: 700;
  }
  p {
    margin: 10px 0 24px;
    width: 236px;
    height: 40px;
    color: ${color.textDark};
    font-size: 16px;
  }
  img {
    position: absolute;
    top: 50%;
    right: 82px;
    transform: translateY(-50%);
    width: 128px;
  }
  ${Media.phone`
    width: 100%;
    padding: 0.56rem 0.64rem 0.64rem;
    border-radius: 0.24rem;
    h4 {
      font-size: 0.44rem;
    }
    p {
      margin: 0.12rem 0 0.32rem;
      width: 3.16rem;
      height: unset;
      min-height: 0.72rem;
      font-size: 0.28rem;
    }
    img {
      right: 0.64rem;
      width: 1.88rem; 
    }
  `}
`
