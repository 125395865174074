import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import Banner from './components/banner'
import Security from './components/security'
import Question from './components/questions'
import Help from './components/help'
import MacReDownload from './components/macReDownload'

const Guidance = () => {
  return (
    <Layout>
      <Banner />
      <MacReDownload />
      <Security />
      <Question />
      <Help />
    </Layout>
  )
}

export default Guidance
