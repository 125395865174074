import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const SecurityWrapper = styled.div`
  margin-bottom: 112px;
  h2 {
    color: #1f2329;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }
  ${Media.phone`
    margin-bottom: 0.92rem;
    h2 {
      font-size: 0.48rem;
    }
  `}
`

export const SecurityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 56px;
  ${Media.phone`
    justify-content: center;
    gap: 0.2rem;
    margin-top: 0.48rem;
  `}
`

export const SecurityItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  flex-shrink: 0;
  width: 140px;
  text-align: center;
  img {
    width: 100%;
  }
  p {
    height: 36px;
    color: ${color.textDark};
    font-size: 14px;
  }
  ${Media.phone`
    width: 1.6rem;
  `}
`
