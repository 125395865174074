import React from 'react'
import { Warpper } from '@raylink-overseas/common/components'
import { useMedia } from '@raylink-overseas/common/hooks'
import { SecurityContainer, SecurityItem, SecurityWrapper } from './atoms'
import ISO27001 from '../../../../images/install/iso27001.png'
import HIPAA from '../../../../images/install/hipaa.png'
import TPN from '../../../../images/install/tpn.png'
import IABM from '../../../../images/install/iabm.png'
import DPP from '../../../../images/install/dpp.png'
import DPPproduction from '../../../../images/install/dpp-production.png'
import DPPbroadcast from '../../../../images/install/dpp-broadcast.png'

const certificateList = [
  {
    img: ISO27001,
    text: 'ISO27001 Certification',
  },
  {
    img: HIPAA,
    text: 'HIPAA Verified',
  },
  {
    img: TPN,
    text: 'TPN Verified',
  },
  {
    img: IABM,
    text: 'Member of iabm',
  },
  {
    img: DPP,
    text: 'Member of DPP',
  },
  {
    img: DPPproduction,
    text: 'DPP Production Certified',
  },
  {
    img: DPPbroadcast,
    text: 'DPP Broadcast Certified',
  },
]

export default function Security() {
  const isMedia = useMedia()

  return (
    <SecurityWrapper>
      <Warpper>
        <h2>Industry-Acknowledged Security</h2>
        <SecurityContainer>
          {certificateList.map(item => (
            <SecurityItem key={item.text}>
              <img src={item.img} alt="" loading="lazy" />
              {!isMedia && <p>{item.text}</p>}
            </SecurityItem>
          ))}
        </SecurityContainer>
      </Warpper>
    </SecurityWrapper>
  )
}
